import { Source, Layer } from "@urbica/react-map-gl";
import React from "react";

//Handles the full process for displaying a markerGroup
const LineMarkerGroup = ({ lineData, togglePolyPopUp }) => {
  var renderableLineData = lineData?.map((el) => {
    return {
      type: "Feature",
      name: el.name,
      color: el.color,
      description: el.description,
      geometry: {
        type: "LineString",
        coordinates: el.track,
      },
    };
  });

  function onClickEvent(event) {
    var _polyPopUp = {
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
      content:
        lineData[event.features[0].source.toString().split("_")[1]].description,
    };
    togglePolyPopUp(_polyPopUp);
  }

  if (!renderableLineData?.[0]) {
    return <></>; //Nothing to render
  }

  return renderableLineData.map((renderableLine, index) => {
    const sourceId = `Source_${renderableLine.name}${renderableLine.color}${index}`;
    const layerId = `${renderableLine.name}${renderableLine.color}${index}`;

    return (
      <React.Fragment key={sourceId}>
        <Source
          id={sourceId}
          type="geojson"
          data={renderableLine}
        />
        <Layer
          key={layerId}
          id={layerId}
          type="line"
          source={sourceId}
          onClick={(event) => onClickEvent(event)}
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": renderableLine.color,
            "line-width": 8,
            "line-opacity": 1,
          }}
        />
      </React.Fragment>
    );
  });
};

export default LineMarkerGroup;
