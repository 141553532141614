import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../lang/en.json";
import Deutsch from "../lang/de.json";
import Welsh from "../lang/cy.json";
import French from "../lang/fr.json";
import Finnish from "../lang/fi.json";
import Dutch from "../lang/nl.json";
import Spanish from "../lang/es.json";
import Italian from "../lang/it.json";

export const Context = React.createContext();

const local = navigator.language;

let lang = English;
if (local === "en") {
  lang = English;
} else {
  if (local === "de") {
    lang = Deutsch;
  } else if (local === "cy") {
    lang = Welsh;
  } else if (local === "fr") {
    lang = French;
  } else if (local === "fi") {
    lang = Finnish;
  } else if (local === "nl") {
    lang = Dutch;
  } else if (local === "es") {
    lang = Spanish;
  } else if (local === "it") {
    lang = Italian;
  }
}

const LanguageContext = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  function selectLanguage(e) {
    const newLocale = e;
    setLocale(newLocale);
    if (newLocale === "en") {
      setMessages(English);
    } else {
      if (newLocale === "de") {
        setMessages(Deutsch);
      } else if (newLocale === "cy") {
        setMessages(Welsh);
      } else if (newLocale === "fr") {
        setMessages(French);
      } else if (newLocale === "fi") {
        setMessages(Finnish);
      } else if (newLocale === "nl") {
        setMessages(Dutch);
      } else if (newLocale === "es") {
        setMessages(Spanish);
      } else if (newLocale === "it") {
        setMessages(Italian);
      }
    }
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider
        messages={messages}
        locale={locale}
      >
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default LanguageContext;
